.tooltip-container {
  position: relative;
  display: block;
  cursor: pointer;
  filter: drop-shadow(0px 0px 2px #b2b2b2);
}

.tooltip-content {
  visibility: hidden;
  width: 300px;
  background-color: white;
  color: black;
  text-align: left;
  border-radius: 6px;
  padding: 20px;
  position: absolute;
  z-index:99999999;
  bottom: 180%;
  left: 50%;
  margin-left: -265px;
  /* opacity: 1; */
  /* transition: opacity 0.3s, visibility 0.3s; */
}

.tooltip-content::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 50%;
  margin-right: -130px;
  border-width: 14px;
  /* z-index:99999999; */
  border-style: solid;
  border-color: white transparent transparent transparent; /* White arrow with transparent sides */
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
  display: block;
}