.App {
    text-align: center;
    direction: ltr;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.tooltip-list-draggable {
text-decoration:none;
position:relative;
}

.tooltip-list-draggable span {
display:none;
-moz-border-radius:6px;
-webkit-border-radius:6px;
border-radius:6px;
color:black;
background:white; 
z-index: 9999;
}

.tooltip-list-draggable span img {
float:left;
margin:0px 2px 2px 0;
}

.tooltip-list-draggable:hover span {
display:block;
position:absolute;
top:0;
right:0;
z-index:1000;
width:auto;
max-width:200px;
min-height:128px;
border:1px solid black;
margin-top:12px;
margin-left:32px;
overflow:hidden;
padding:8px;
}