.sweet-alert {
  padding: 40px 20px !important;
  h2,
  h2 * {
    color: #595959;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    text-transform: none;
    position: relative;
    margin: 0 0 0.4em;
    padding: 0;
    display: block;
    word-wrap: break-word;
  }
  .lead {
    font-size: 18px;
    text-align: center;
    font-weight: 300;
    position: relative;
    float: none;
    margin: 0;
    padding: 0;
    line-height: normal;
    color: #545454 !important;
    word-wrap: break-word;
  }
  input.form-control {
    color: black;
  }
  margin: auto !important;
}
