.popover {
  border: 0;
}

.popover-header {
	font-weight: $font-weight-bold;
}

// Alternative colors
@each $color, $value in $theme-colors {
    .popover-#{$color} {
        @include popover-variant($value);
    }
}
