.rtl {
  .sidebar,
  .bootstrap-navbar {
    right: 0;
    left: auto;
    margin-right: 20px;
    margin-left: 0;

    .nav {
        i {
          float: right;
          margin-left: 15px;
          margin-right: 0;
        }

        p {
          margin-right: 45px;
          text-align: right;
        }

        .caret {
          left: 11px;
          right: auto;
        }

        li.active a {
          &:before {
            left: auto !important;
            right: -4px;
          }
        }
    }

    .logo {
        a.logo-mini {
          float: right;
          margin-right: 20px;
          margin-left: 10px;
        }

        .simple-text {
          text-align: right;
        }
    }

    .sidebar-wrapper .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-mini-icon,
    .sidebar-wrapper .user .info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-mini-icon {
      float: right;
      margin-left: 15px;
      margin-right: 0;
    }

    .sidebar-wrapper > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
    .sidebar-wrapper .user .info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal {
      text-align: right;
    }

    &:before {
      right: 30px;
      left: auto;
    }
  }

  .main-panel {
    position: fixed;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .main-panel .content {
    padding: 110px 300px 20px 50px
  }

  .dropdown-toggle:after {
    margin-right: .255em;
    margin-left: 0;
  }

  .dropdown-menu.dropdown-menu-right.dropdown-navbar {
    right: -220px !important;
    left: auto;

    &:before {
      right: auto;
      left: 35px;
    }
  }

  .notification {
    left: 40px;
    right: auto;
  }

  .dropdown-menu {
    right: auto;
    left: 0;
  }

  .card-timeline .timeline .timeline-footer .btn {
    margin-left: 0;
    margin-right: auto;
  }

  .navbar-minimize-fixed {
    margin-right: 38px;
    margin-left: auto;
  }

  .minimize-sidebar {
    float: right;
  }

  .alert {
    left: 0;
    margin-left: 0;
    margin-right: 0;
      button.close{
        left: 10px !important;
        right: auto !important;
      }

      span[data-notify="icon"] {
        right: 15px;
        left: auto;
      }



      &.alert-with-icon {
        padding-right: 65px;
        padding-left: 15px;
      }

      &.alert-with-icon i[data-notify="icon"] {
        right: 15px;
        left: auto;
      }
  }

  .search-bar {
    margin-left: 0;
  }

  .modal-search .modal-header .close {
    margin-right: auto;
    left: 10px;
  }

  .rtl .footer {
    padding: 24px 30px !important;
  }

  .ps__rail-y {
    right: auto !important;
    left: 0;
  }

  .card.card-timeline {
    .timeline .timeline-footer {
      .btn.dropdown-toggle {
        i {
          left: 0 !important;
        }

        &:after {
          margin-left: 5px !important;
        }
      }
    }
  }

  .fixed-plugin .dropdown-menu {
    right: 80px;
  }
}



@include media-breakpoint-up(xl) {
  .rtl{
    &.sidebar-mini .main-panel .content {
      padding-right: 130px;
      padding-left: 50px;
    }

    &.sidebar-mini footer {
      padding-right: 130px;
      padding-left: 50px;
    }

    .navbar-minimize button {
      margin-right: -5px;
    }

    .footer {
      padding: 24px 300px 24px 0;
    }
  }
}

@include media-breakpoint-down(lg) {

  .nav-open {
    .rtl .wrapper {
      @include transform-translate-x(-260px);
      @include transitions (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    }
  }


  .rtl {
    .sidebar {
      @include transform-translate-x(260px);
    }

    .main-panel {
      float: right;
      right: 0;
      left: auto;
      @include transform-translate-x(0px);
    }
  }

  .rtl .sidebar {
    margin-right: 0;
  }

  .rtl .main-panel .content{
    padding-right: 50px;
  }
}

@include media-breakpoint-down(md) {
    .rtl .main-panel .content {
      padding-right: 50px;
    }

    .rtl #bodyClick {
      right: 260px;
      left: auto;
    }

    .rtl .navbar .navbar-toggle .navbar-toggler {
        display: block;
        margin-right: 20px;
    }

    .rtl .navbar {
      .navbar-nav {
        padding-right: 0;
        a.nav-link {
          text-align: right;
          p{
            margin-right: 7px;
          }
        }

        .btn {
          margin-right: 0;
          padding: 0;
          i{
            margin-left: 4px;
            margin-right: 5px;
          }
        }

        .search-bar span{
          margin-right: 10px;
        }
      }
    }
    .nav-open {
      body {
        position: fixed;
      }
    }
  }


@include media-breakpoint-down(sm) {
  .rtl {
    .main-panel .content {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}


@include media-breakpoint-down(xs) {

.rtl .navbar .dropdown-menu {
    width: 200px !important;
  }
}
